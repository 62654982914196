@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/normalize";
@import "utils/css-variables__utils";
@import '../../node_modules/swiper/swiper-bundle';
// @import "flatpickr/dist/flatpickr.min"; // no need ./node_modules

html,
body {
    height: 100%;
}

html {
    font-size: $main-font-size;
    line-height: 1.5;
}

body {
    margin: 0;
    padding: 0;
    font-family: $main-font;
    line-height: 1.5;
    font-weight: normal;
    color: $color-text;
    background-color: $color-secondary;
    @extend %antialias;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    scroll-behavior: smooth;
    &.menu-open,
    &.loading{
        overflow: hidden;
    }
}
*,
*:before,
*:after {
    box-sizing: border-box;
}
main{
    scroll-behavior: smooth;
    position: relative;
}
img,
svg {
    max-width: 100%;
}

svg {
    fill: currentColor;
    max-height: 100%;
}

button,
a {
    cursor: pointer;
}
.color-primary{
    color: var(--color-primary) !important;
}
@include r(767){
    a[href^=http]{
        overflow-wrap: break-word;
    }
}

strong,
.bold {
    font-weight: 700;
}

.layout {
    position: relative;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    &__header,
    &__footer{
        flex-shrink: 0;
    }
    &__header{
        z-index: 100;
    }
    &__main{
        flex-grow: 1;
        padding-top: var(--headerHeight);
        position: relative;
    }
    &:not(.coloring-page){
        .layout__main{
            overflow: hidden;
        }
    }
    &__decor{
        // position: absolute;
        position: fixed;
        width: 100%;
        height: 100%;
        pointer-events: none;
        overflow: hidden;
        &_top{
            top: 0;
            left: 0;
            // z-index: 1;
            z-index: 4;
            // height: 600px;
            height: calc(var(--headerHeight) * 2.5);
            min-height: calc(var(--headerHeight) * 2.5);
            transform-origin: 0 0;
            will-change: min-height;
            transition: transform .3s ease-out, min-height .245s ease;
            .wave{
                position: absolute;
                // width: 100%;
                top: 0;
                left: 0;
                background-size: 50% 99%;
                width: 200%;
                min-width: 2000px;
                height: 100%;
                background-repeat: repeat no-repeat;
                background-position: 0 2px;
                transform-origin: center top;
                animation: move_wave 150s cubic-bezier(.55,.5,.45,.5) infinite both;
            }
            .wave1{
                background-image: url(../img/decor/wave1_alt.svg);
                z-index: 4;
                top: -76%;
                animation-delay: -20s;

            }
            .wave2{
                background-image: url(../img/decor/wave2_alt.svg);
                z-index: 3;
                top: -70%;
                animation-duration: 220s;
                animation-delay: -30s;
                animation-name: move_wave-back;
            }
            .wave3{
                background-image: url(../img/decor/wave3_alt.svg);
                z-index: 2;
                top: -61%;
                animation-duration: 300s;
                animation-delay: -40s;
            }
            .wave4{
                background-image: url(../img/decor/wave4_alt.svg);
                top: -50%;
                z-index: 1;
                animation-duration: 400s;
                animation-delay: -50s;
                animation-name: move_wave-back;
            }
        }
    }
}





.center-page{
    .layout__main{
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        @include r(1199){
            overflow: hidden;
        }
    }
}
.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    max-width: 1176px;
    @include rmin(1500) {
        max-width: 1440px;
    }
}

.underlineHideOnHover{
    @include underlineHideOnHover;
    &a{
        text-decoration: none;
        vertical-align: bottom;
    }
}


@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.85)
    }
    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}
@keyframes move_wave-back {
    0% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.85)
    }
    100% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }
}


.visually-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

.img-generated-wrapper{
    // 875/93 - box shadow
    display: block;
    padding-top: calc(22/873)*100%;
    padding-bottom: calc(93/875)*100%;
    margin-bottom: calc(-93/875)*100%;
    position: relative;
    /*top*/
    &::before{
        content: '';
        @include aspect-ratio(874,260);
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 26.4%;
        background: center / contain no-repeat url(../img/box/box3d-top.svg);
        z-index: 2;
        // 873/260 - box 3d with border-left
        // 873/22 - only top box
    }
    /*bottom*/
    &::after{
        content: '';
        @include aspect-ratio(875,93);
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: -1;
        background: center / cover no-repeat radial-gradient(ellipse at top, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 60%, rgba(255,255,255,0) 100%);
    }
    &.preview-pack__img{
        margin-bottom: calc(-93/875)*100%;
    }
}

.img-generated{
    display: block;
    width: 100%;
    @include aspect-ratio(1937,534,true);
    z-index: 1;
    // box without shadow

    img{
        @extend %overlay-full;
        object-fit: cover;
        object-position: center;
    }
}


// Components
@import "components/index";
@import "pages/index.sass";

// Utils
@import "utils/utils";



// @media screen and (prefers-reduced-motion: reduce) {
//     * {
//       animation-duration: 0.001ms !important;
//       animation-iteration-count: 1 !important;
//       transition-duration: 0.001ms !important;
//     }
// }
@media (inverted-colors) {
    img, video {
        filter: invert(100%);
    }
}


/// safe-area
// .cookieNotice {
//     padding-bottom calc( env(safe-area-inset-bottom) + 20px )
// }
