// Font
$main-font: 'Intro', sans-serif;
$font-alwyn: 'Alwyn', sans-serif;
$title-font: 'Cardenio Modern', sans-serif;

$main-font-size: 18px;

// Colors
$color-primary: #E81178;
$color-secondary: #493280;
$color-purple: #806BB0;
$color-purple-dark: #665293;
$color-text: #FFFFFF;
$color-check: #94D145;
$color-yellow: #F6DD28;

:root {
    --color-primary: #E81178;
    --color-secondary: #493280;
    --color-purple: #806BB0;
    --color-text: #FFFFFF;
    --color-check: #94D145;
    --color-yellow: #F6DD28;
    --mainFz: 18px;
    --headerHeight: 8.445em;

    --color-btn-text: inherit;
    --color-btn-bg: var(--color-primary);

    --descriptionWidth: 800px;
    @media (min-width: 1921px) {
        --descriptionWidth: 1100px;
    }
}

// Transitions
$transition-base: 0.33s cubic-bezier(.23, 1, .32, 1);
$transition-slow: .6s cubic-bezier(0.08, 1.00, 0.21, 1.00);


$menuMob: 1200;