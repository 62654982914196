.coloring-page{

}
.coloring{
    padding-top: 3vmax;
    padding-bottom: 3vmax;
    z-index: 1;
    position: relative;
    &__title{
        text-align: center;
    }
    &__description{
        max-width: var(--descriptionWidth);
        margin: 0 auto;
        text-align: center;
        .smaller{
            font-size: 80%;
        }
    }
    &__download{
        text-align: center;
        .underlineHideOnHover{
            vertical-align: bottom;
        }
    }
    &__decor{
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        .decor{
            &-3{
                position: absolute;
                width: calc(70/1366)*100%;
                right: calc(140/1366)*100%;
                top: 3%;
            }
            &-4{
                position: absolute;
                width: calc(100/1366)*100%;
                left: calc(80/1366)*100%;
                top: 5%;
            }
        }
    }
    &__canvas{
        margin-top: 3vmax;
        margin-bottom: 3vmax;
        position: relative;
        // background-color: #c4c4c4;
        @include rmin(1023){
            min-height: mIn(80vh, 800px);
        }
        .coloring-start{
            opacity: .6;
            @extend %overlay-full;
        }
        .btn{
            &.canvas-btn-1{
                z-index: 10;
                position: sticky;
                left: 50%;
                bottom: 0;
                transform: translate(-50%,-50%);
                font-size: 18px;
                text-transform: capitalize;
                font-family: $main-font;
                overflow: visible;
                // @include r(767){
                //     left: 0;
                //     transform: translateY(-50%) scale(0.7);
                // }
                &::before,
                &::after{
                    transform: scaleX(1.2) scaleY(1.7) translate(-43%,-23%)
                }
                &.is-disabled{
                    pointer-events: none;
                    &::before{
                        filter: grayscale(100%);
                    }
                }
            }
        }
    }

    &__interface{
        display: flex;
        justify-content: space-between;
        align-items: center;

    }


    .cnv{
        margin-top: 30px;
        @include aspect-ratio(349,199,true);
        @include r(1023){
            margin-top: 20px;
            width: calc(100% + 30px);
            margin-left: -15px;
            min-height: 300px;

        }
        .js-canvas-inner{
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            touch-action: none;
            user-select: none;
        }
        .js-canvas-inner svg{
            max-width: none;
            width: auto;
            object-fit: contain;
        }
        svg:not(.svg-icon){
            @extend %overlay-full;
            path{
                &:hover{
                    &.can-colored{
                        animation: fillHover 3s ease infinite;
                    }
                    // &:not(.can-colored){
                    //     cursor: not-allowed;
                    // }
                }
            }
        }
        overflow: hidden;
        &.cursor-off,
        &.cursor-off .js-canvas-inner{
            cursor: none !important;
        }
        &:not(:hover){
            .js-cursor{
                opacity: 0;
            }
        }
        .js-cursor{
            position: absolute;
            z-index: 1999;
            left: 0;
            top: 0;
            width: 100px;
            height: 90px;
            @extend %bg-contain;
            background-image: url(../img/decor/pencil.svg);
            pointer-events: none;
            transition: opacity .23s ease;
            margin-top: -80px;
            margin-left: -7px;
            @include r(767){
                display: none;
            }
        }
        &__btns{
            font-size: 24px;
            &:hover ~ .js-cursor{
                opacity: 0;
            }
        }

    }
}

@keyframes fillHover {
    0%,100%{
        fill: rgb(255,255,255);
    }
    50%{
        fill: var(--selected-color);
    }
}

$elemCount: 36;
.img-handler{
    font-size: 28px;
    & *{
        user-select: none;
    }
    &__dd{
        height: mAx(80vh, 400px);
        overflow: hidden;
        @include r(1023){
            max-height: 118vw;
        }
    }
    &__list{
        min-width: 1600px;
        display: flex;
        flex-flow: row wrap;
        box-shadow: inset 0 0 0 .5px rgba(white,.4);
        @include r(1023){
            min-width: 100%;
        }
        &.is-dragStart{
            .img-handler__item{
                pointer-events: none;
            }
        }
        &.child-selected{
            .img-handler__item:not(.is-active){
                &::after{
                    transform: scale(1);
                }
            }
        }
    }
    &__item{
        flex: 0 0 33.3333%;
        width: 33.3333%;
        display: flex;
        position: relative;
        picture{
            display: flex;
        }
        img{
            // width: 100%;
            transition: opacity .3s;
        }
        &:not(.is-active){
            img{
                opacity: .45;
            }
        }
        &::after{
            content: attr(data-or-this);
            @extend %overlay-full;
            font-size: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $title-font;
            color: #fff;
            transition: transform .3s ease-in-out;
            transform: scale(0);
            @include r(767){
                font-size: 3vmin;
            }
        }
        &::before{
            content: '';
            @extend %overlay-full;
            box-shadow: inset 0 0 0 .5px rgba(white,.4);
        }
        @for $i from 1 through $elemCount {
            &:nth-child(#{$i}){
                &::after{
                    transition-delay: .05s * ($i%5);
                }
            }
        }
    }
    &__move{
        @include abs-center;
        z-index: 10;
        pointer-events: none;
        user-select: none;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        width: min-content;
        font-size: 18px;
        @include r(1023){
            @include css-lock(14,17,450,1023);
        }
        .svg-icon{
            width: 3.8em;
            height: 3.8em;
        }
        span{
            text-transform: uppercase;
            font-weight: bold;
            white-space: nowrap;
        }
    }
    &__zooming{
        @extend %reset-btn;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-primary;
        box-shadow: 0px 20px 30px rgba(73, 50, 128, 0.4), inset 2px 2px 0px rgba(255, 255, 255, 0.25);
        position: absolute;
        right: 1em;
        z-index: 10;
        transition: .3s ease-in-out;
        &-zoom-in{
            bottom: 5.5em;
        }
        &-zoom-out{
            bottom: 3em;
        }
        @include r(767){
            bottom: auto;
            font-size: 20px;
            &-zoom-in{
                top: 4vh
            }
            &-zoom-out{
                top: calc(4vh + 2.5em);
            }
        }
        &:hover{
            transform: translateY(-.2em);
            box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.25),
        }
        &:active{
            background-color: lighten($color-primary,20%);
        }
        .svg-icon{
            width: em(22,28);
            height: em(22,28);
        }
    }
}

// .js-canvas-inner{
//     cursor: url(../img/decor/pencil.png), auto;
// }

.coloring-interface{
    font-size: 24px;
    &__refresh{
        color: #E81178;
        display: inline-flex;
        align-items: center;
        font-family: $title-font;
        @include rmin(1024){
            position: absolute;
        }
        .btn{
            padding-left: 0;
        }
        .svg-icon{
            width: 1em;
            height: 1em;
            margin-right: 0.5em;
            stroke: currentColor;
        }
    }
    &__palette{
        position: relative;
        margin: 0 auto;
        width: min-content;

        &::before{
            content: '';
            @extend %overlay-full;
            @extend %bg-full;
            background-image: url(../img/decor/interface-color-bg.svg);
            z-index: 0;
        }

    }
    @include r(1023){
        flex-wrap: wrap;
        &__palette{
            margin: 1em auto 0;
            order: 2;
            @include r(767){
                margin-top: 0;
            }
        }
    }
    @include r(767){
        width: 100%;
        .palette{
            min-width: 100%;
            width: 100%;
            padding: 0.7em;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: 0.5em;
            &__item{
                margin: 0;
            }
        }

    }

}
.fny{
    display: inline;
    position: relative;
    overflow: visible;
    &.is-visible{
        &::after{
            content: "' '";
            position: absolute;
            width: 1em;
            font-size: 1.1em;
            left: 0em;
            bottom: -0.35em;
        }
        &.is-animate{
            &::after{
                animation: eys 4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            }
        }
    }
}


@keyframes eys {
    0%{
        opacity: 0;
    }
    5%{
        opacity: 1;
        // transform: translateX(0);
    }
    // 50%{
    //     transform: translateX(.03em);
    // }
    70%{
        transform: translateX(-0.07em);
    }
    75%{
        transform: translateX(0);
        opacity: 1;
    }
    89%{
        opacity: 1;
    }
    90%{
        opacity: 0;
    }
    95%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

.palette{
    position: relative;
    min-width: em(400,24);
    min-height: em(80,24);
    display: inline-flex;
    align-items: center;
    padding-left: em(40,24);
    padding-right: em(40,24);
    &__item{
        width: em(40,24);
        height: em(40,24);
        border-radius: 50%;
        overflow: hidden;
        transition: $transition-base;
        @extend %reset-btn;
        & + .palette__item{
            margin-left: em(10,24);
        }
        &:hover,
        &_is-active{
            transform: scale(1.2);
        }
    }
}

.color-part-form{
    font-size: 20px;
    @include rmin(1921){
        @include css-lock(20,35,1921,2600);
    }
    &__title{
        font-size: em(24,20);
        font-family: $title-font;
        margin-bottom: 0.4em;
        text-align: center;
        text-transform: uppercase;
    }
    &__type-name{
        text-align: center;
    }
    &__inner{
        margin: 3vmax auto 0;
        position: relative;
        max-width: em(400,20);

        .field{
            margin: .6em auto 1em;
            // padding: em(10) em(40);
            display: flex;
            width: 100%;
            position: relative;
            // &__bg{
            //     @extend %overlay-full;
            //     @extend %bg-full;
            //     background-image: url(../img/decor/inspiration-text-bg.svg);
            //     z-index: -1;
            // }
            &__input{
                padding: .7em 1.5em;
                background-color: transparent;
                border: 1px solid rgba(255, 255, 255, 0.3);
                border-radius: 12px;
                font-family: $title-font;
                font-size: 1em;
                text-align: center;
                width: 100%;
                transition: $transition-base;
                &.error{
                    border-color: rgba(172, 42, 42, .5);
                    & ~ .field__error{
                        opacity: 1;
                    }
                }
                @include placeholder{
                    text-transform: lowercase;
                    color: rgba(255, 255, 255, 0.6);
                }
                &:focus{
                    outline: none;
                    box-shadow: 0 1px 2px currentColor;
                    & ~ .field__count{
                        opacity: 1;
                    }
                }
            }
            &__error{
                color: $color-primary;
                font-size: 70%;
                text-align: center;
                position: absolute;
                display: block;
                left: 0;
                top: 100%;
                opacity: 0;
            }
            &__count{
                font-size: .56em;
                position: absolute;
                top: 4px;
                right: 6px;
                opacity: 0;
                text-shadow: 1px 1px var(--color-purple), 2px 2px var(--color-purple);
                z-index: 2;
            }
        }
        .btn{
            margin: 2vmax auto 0;
            display: flex;
        }
    }

}


.preview{
    padding-top: 3vmax;
    padding-bottom: 3vmax;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 1;
    &__decor{
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        .decor{
            &-1{
                position: absolute;
                width: calc(100/1366)*100%;
                left: calc(30/1366)*100%;
                top: 50%;
            }
            &-2{
                position: absolute;
                width: calc(100/1366)*100%;
                right: calc(30/1366)*100%;
                top: 5%;
            }
        }
    }
    &__container.container{
        position: relative;
        @include rIn(1024,1921){
            max-width: 900px;
        }
        &::before{
            content: '';
            @extend %overlay-full;
            background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            opacity: 0.2;
            z-index: -1;
        }
    }
    &__top-line{
        margin-bottom: 2em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn_back{
            position: absolute;
        }
        @include r(1023){
            .btn.btn_back{
                padding-left: 0;
            }
        }
        @include r(767){
            flex-wrap: wrap;
            .preview__title{
                flex: 1 1 100%;
                max-width: 100%;
            }
            .btn.btn_back{
                padding: 0 0.4em;
                position: relative;
            }
        }
    }
    &__title{
        margin: 0 auto;
    }
}
.preview-pack{
    &__sharing{
        position: relative;
        @include r(767){
            font-size: .8em;
        }
    }
    &__img{
        margin-bottom: -9%;
        img{
            width: 100%;
        }
    }
    &__arrow{
        position: absolute;
        color: $color-yellow;
        transform: rotate(-33deg);
        width: em(65,20);
        height: em(50,20);
        top: 11%;
        left: calc(50% + 139px);
    }

    .form-button{
        margin-top: 3vmax;
        min-width: 200px;
        font-size: em(24,20);
    }
}
.radio-wrapper,
.checkbox-wrapper{
    margin-bottom: 1em;
    position: relative;
    width: 100%;
    display: flex;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    &_inline{
        display: inline-flex;
        width: auto;
        & ~ .radio-wrapper_inline,
        & ~ .checkbox-wrapper_inline{
            margin-left: .75em;
        }
    }
    &:hover{
        cursor: pointer;
    }
    .pseudo{
        position: relative;
        width: em(18,14);
        min-width: em(18,14);
        height: em(18,14);
        border-radius: 3px;
        border: 1px solid #806BB0;
        display: flex;
        transition: background-color .3s;

        &::after{
            content: '';
            @extend %overlay-full;
            @extend %bg-contain;
            background-image: url(../img/decor/check.svg);
            opacity: 0;
            transition: .3s;
            width: 60%;
            height: 60%;
            @include abs-center();
        }
    }
    .text{
        padding-left: .8em;
    }
    input:checked ~ .pseudo{
        background-color: var(--color-primary);
        &::after{
            opacity: 1;
        }
    }

    a{
        color: inherit;
        text-decoration: underline;
    }
}

.radio-wrapper{
    margin-bottom: 0;
    .pseudo{
        border-radius: 50%;
        &::after{
            background-color: currentColor;
        }
    }
}

.sharing{
    padding-top: 3vmax;
    padding-bottom: 3vmax;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 1;
    &__decor{
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        .decor{
            &-1{
                position: absolute;
                width: calc(100/1366)*100%;
                left: calc(30/1366)*100%;
                top: 10%;
            }
            &-2{
                position: absolute;
                width: calc(100/1366)*100%;
                right: calc(30/1366)*100%;
                top: 5%;
            }
            &-3{
                position: absolute;
                width: calc(100/1366)*100%;
                left: calc(200/1366)*100%;
                top: 50%;
            }
            &-4{
                position: absolute;
                width: calc(70/1366)*100%;
                right: calc(200/1366)*100%;
                top: 70%;
            }
        }
    }
    &__line{
        margin: 2em auto 1em;
        padding: .2em 0.35em;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: min-content;
        // min-width: 400px;
        text-align: center;
        min-height: 60px;
        @include rmin(1024){
            min-height: 115px;
            padding: .5em;
            .at-icon-wrapper{
                line-height: 96px !important;
                height: 96px !important;
                width: 96px !important;
                &   > svg{
                    height: 96px !important;
                    width: 96px !important;
                }
            }
            .insta-link{
                width: 96px;
                height: 96px;
            }
            .sharing__arrow{
                font-size: 1.5em;
                left: calc(50% + 126px);
            }
        }
        &::before{
            content: '';
            z-index: -1;
            @extend %overlay-full;
            border-radius: 10px;
            background-color: #fff;
            // @extend %bg-full;
            // background-image: url(../img/decor/sharing-text-bg.svg);
        }
        .at-share-btn-elements{
            display: inline-flex;
        }
        .at-resp-share-element .at-share-btn{
            padding: 0 !important;
            margin: 0 !important;
        }
        .insta-link{
            margin-left: 15px;
            width: 48px;
            height: 48px;
            transition: all .2s ease-in-out;
            &:hover{
                transform: translateY(-4px);
            }

        }

    }
    &__arrow{
        position: absolute;
        color: $color-yellow;
        transform: rotate(-33deg);
        width: em(65,20);
        height: em(50,20);
        top: 0%;
        left: calc(50% + 100px);
    }
    &__img{
        // margin-bottom: -3em;
        max-width: var(--descriptionWidth);
        margin: 0 auto 0.5em;
    }
    &__bottom{
        margin-bottom: 1em;
        ._bigger{
            margin: 0 auto -.5em;
            font-size: 1.25em;
            display: block;
            max-width: 25em;
        }
        .italic{
            margin: 1em auto;
            display: block;
            font-size: 16px;
        }
    }
    &__insta{
        .title{
            font-family: $title-font;
            font-size: 28px;
            margin-bottom: .7em;
        }
        ol{
            max-width: 30em;
            margin: 0 auto;
            padding: 0;
            font-size: 18px;
            margin-bottom: 1.6em;
            list-style: none;
            text-align: left;
            @include counter('sharing',' ');
            li{
                padding-left: 2em;
                margin-bottom: 1em;
                position: relative;
                &::before{
                    position: absolute;
                    background: url(../img/decor/list-dots.svg) no-repeat center;
                    background-size: cover;
                    width: em(28,18);
                    height: em(28,18);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 0;
                }
            }
        }

        .sharing-btn-bottom{
            font-size: 24px;
            color: #FFFFFF;
            min-width: 7em;
        }
    }
    &__img-preview{
        max-width: var(--descriptionWidth);
        margin: 1em auto;
        display: grid;
        grid-template-columns: 51.61% 27.06% 15.41% ;
        justify-content: space-between;
        figure{
            margin: 0;
            img{
                width: 100%;
            }
        }
    }
    &__bottom-text{
        margin: 1em auto 3vmax;
        display: block;
        max-width: var(--descriptionWidth);
        a{
            @include underlineHideOnHover;
            vertical-align: bottom;
            color: var(--color-primary);
        }
    }
}


.img-handler__list {
    touch-action: none;
    user-select: none;
}
@include r(1199){
    .img-handler__list {
        width: auto;
        aspect-ratio: 3 / 3.5;
        min-height: 100%;
    }

}

.drag-demo{
    display: block;
    width: 100%;
    height: 50vh;
    background-color: #806BB0;
    overflow: hidden;
    #drag-1,
     #drag-2 {
        width: 25%;
        min-height: 6.5em;
        margin: 1rem 0 0 1rem;
        background-color: #29e;
        color: white;
        border-radius: 0.75em;
        padding: 4%;
        touch-action: none;
        user-select: none;
        transform: translate(0px, 0px);
      }
      #drag-1{
          width: 150%;
          height: 150%;
          background: repeating-radial-gradient(circle at 50%, var(--color-secondary), var(--color-secondary) 10px, var(--color-purple) 10px, var(--color-purple) 20px);
          touch-action: none;
        }
}



