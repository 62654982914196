.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.text-nowrap {
    white-space: nowrap;
}
.hidden{
    display: none !important;
}

img[width]{
    height: auto;
}
.w-100{
    width: 100%;
}
