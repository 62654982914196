.swiper-container{
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    width: 100%;
    & *{
        user-select: none;
    }
}

:root{
    --sliderArrowSize: 30px;
    @media (min-width: 1024px) {
        --sliderArrowSize: 38px;
        --sliderArrowSize: clamp(1.667rem, 1.2vw + 1rem, 2.111rem);
    }
}

.inspiration-slider{
    padding-bottom: 5em;
    margin-bottom: -8%;
    [data-label-position="bottom"] &{
        padding-bottom: 12em;
    }
    &.swiper-container{
        // padding-left: 0.75em;
        // padding-right: 0.75em;
        padding-left: calc(var(--sliderArrowSize) * .5);
        padding-right: calc(var(--sliderArrowSize) * .5);
    }
    &__arrow{
        position: absolute;
        // top: 33%;
        top: 0;
        margin-top: calc(15.2% - calc(var(--sliderArrowSize) * .5));
        z-index: 5;
        width: var(--sliderArrowSize);
        height: var(--sliderArrowSize);
        border-radius: 50%;
        background-color: var(--color-purple);
        border: 2px solid #4f3784;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $transition-base;
        /*just for best UX on mob*/
        &::before{
            content: '';
            position: absolute;
            @include abs-center;
            width: 50px;
            height: 50px;
        }
        &:hover{
            cursor: pointer;
            transform: scale(1.1);
            background-color: $color-purple-dark;
        }
        &-prev{
            left: 0;
        }
        &-next{
            right: 0;
        }
        .svg-icon{
            width: 50%;
            height: 50%;
        }
        // [data-label-position="bottom"] &{
        //     top: 25%;
        // }
    }
    &__counter{
        display: block;
        transform: translateY(-2em);
    }
    .inspiration-list__who,
    .inspiration-list__arrow{
        transition: $transition-base;
    }
    .inspiration-list__who{
        transform: translateY(-1em);
        opacity: 0;
        transition-delay: .5s;
    }
    .inspiration-list__arrow{
        transform: translateX(-1.5em) rotate(20deg);
        opacity: 0;
        transition-delay: .7s;
    }
    .inspiration-list__item.swiper-slide-active{
        .inspiration-list__who{
            transform: none;
            opacity: 1;
        }
        .inspiration-list__arrow{
            transform: none;
            opacity: 1;
        }
    }
    &__wrapper{
        overflow: hidden;
    }


    &__decor{
        pointer-events: none;
        @include aspect-ratio(1007,407,true);
        position: absolute;
        width: calc(100% - var(--sliderArrowSize));
        z-index: 2;
        left: calc(var(--sliderArrowSize) * .5);
        top: 0;
        .slideImgWrapper{
            @extend %overlay-full;
        }
    }
    .inspiration-list__box{
        @include aspect-ratio(1007,301,true);
        .slideImg{
            position: absolute;
            height: 91.85%;
            object-fit: contain;
            right: 0;
            width: auto;
            bottom: 0%;
        }
    }
    &.swiper-initialized{

    }
}

.inspiration-slider__block{
    padding-bottom: 5vmax;
    display: flex;
    flex-flow: row wrap;
    .inspiration_home &{
        padding-bottom: 3vmax;
    }
    .inspiration-slider__title{
        flex: 1 1 100%;
        max-width: 100%;
        .inspiration-list__title{
            margin: 25px auto;
            @include r(767){
                margin: 10px auto 25px;
            }
        }
        &_top{
            order: -1;
            .svg-icon_inspiration-arrow{
                transform: scaleY(-1) rotate(-24deg) translate(0, -1em);
            }
        }


    }
    &[data-label-position="bottom"] .inspiration-slider__title{
        margin-top: -8em;
        @include rmin(1921){
            margin-top: -6.5em;
        }
    }

    .inspiration-list__who{
        min-height: 5em;
        .top,
        .bottom{
            overflow: hidden;
        }
    }
}

.inspiration-slider-description,
.inspiration-slider-pencil{
    transition: opacity .3s ease-in;
    &__item{
        position: absolute;
        .letter{
            animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            @for $i from 1 through 20 {
                &:nth-child(#{$i}) {
                    animation-delay: .05s * ($i%5);
                }
            }
        }
        // &:not(.is-active){
        //     display: none;
        // }
        &.is-active{
            .letter{
                animation: bounce-in-top 1.1s both;
                @for $i from 1 through 20 {
                    &:nth-child(#{$i}) {
                        animation-delay: .05s * ($i%5);
                    }
                }
            }
        }
    }
    &.is-visible{
        opacity: 1 !important;
    }
}

/*pencil 3.6deg 542/1931*/
.inspiration-slider-pencil{
    @extend %overlay-full;
    pointer-events: none;
    &__item{
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(542/1931)*100%;
        color: var(--color-secondary);
        top: 56.5%;
        left: 18%;
        transform: rotate(3.6deg);
        white-space: nowrap;
        overflow: hidden;
        .left{
            margin-right: .5em;
        }
        .left,
        .right{
            color: var(--color-secondary);
            font-family: $title-font;
            font-size: .65em;
        }
    }
}


.inspiration-linked-slider{
    margin-top: 3vmax;
    margin-left: 0.75em;
    margin-right: 0.75em;
    // pointer-events: none;
}



 @keyframes bounce-in-top {
    0% {
      transform: translateY(-100%);
      animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      transform: translateY(0);
      animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      transform: translateY(-13%);
      animation-timing-function: ease-in;
    }
    72% {
      transform: translateY(0);
      animation-timing-function: ease-out;
    }
    81% {
      transform: translateY(-5%);
      animation-timing-function: ease-in;
    }
    90% {
      transform: translateY(0);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translateY(1.5%);
      animation-timing-function: ease-in;
    }
    100% {
      transform: translateY(0);
      animation-timing-function: ease-out;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-12-17 17:23:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
@keyframes slide-out-bottom {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  }
