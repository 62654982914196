.legal-page{
    text-align: left;
    h1,h2,h3,h4{
        text-align: center;
    }
    h2,.h2{
        font-size: 1.8rem;
        margin: 1em 0 0.5em;
    }
    h4,.h4{
        margin: 1em 0 0.5em;
    }
    &__content{
        max-width: 754px;
        margin-left: auto;
        margin-right: auto;
        a:not(.underlineHideOnHover) {
            position: relative;
            font-weight: 600;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        .underlineHideOnHover{
            vertical-align: bottom;
        }

        .h2{
            margin-bottom: 30px;
            text-transform: uppercase;
        }
        .h3{
            text-transform: uppercase;
        }
    }
    .list-marker{
        list-style: circle;
        padding-left: 25px;

        li:first-child{
            margin-bottom: 15px;
        }
    }
    .ot-sdk-show-settings{
        color: #333;
    }
}