[data-tippy-root] {
    max-width: calc(100vw - 10px);
}
.tippy{
    &-box {
        position: relative;
        background-color: $color-primary;
        color: $color-text;
        padding: 14px 11px;
        border-radius: 0;
        font-weight: 400;
        font-size: 15px;
        outline: 0;
        transition-property: transform, visibility, opacity;
        &::before{
            content: '';
            @extend %overlay-full;
            background-color: inherit;
            z-index: -1;
            transform: skewX(-8deg) scale(1.06);
        }
        &[data-animation="fade"][data-state="hidden"] {
            opacity: 0;
        }

        &[data-placement^="top"] > .tippy-arrow {
            top: 100%;
            border-bottom-color: transparent;
            border-left-color: transparent;
        }

        &[data-placement^="bottom"] > .tippy-arrow {
            bottom: 100%;
            border-top-color: transparent;
            border-left-color: transparent;
        }

        &[data-inertia][data-state="visible"] {
            transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
        }
    }
    &-arrow {
        width: 16px;
        height: 16px;
        color: $color-primary;
        border: 10px solid;
    }
}

[data-tippy-content]{
    cursor: pointer;
}
