.inspiration-page{
    .inspiration{
        padding-top: 3vmax;
        padding-bottom: 3vmax;
        z-index: 1;
        @include r(1199){
            overflow: hidden;
        }
        &__description{
            max-width: var(--descriptionWidth);
            margin: 0 auto;
        }
    }
}


.subtitle{
    margin-top: -0.5em;
    margin-bottom: 1.5em;
    @include rmin(450){
        margin-top: -1em;
        margin-bottom: 2em;
    }
}

.inspiration{
    margin-top: -10vmax;
    position: relative;
    min-height: mIn(100vh, 520px);
    padding-top: 10vmax;
    padding-bottom: 10vmax;
    &_home{
        padding-top: 5vmax;
        @include r(1199){
            overflow: hidden;
        }
    }
    &::before{
        content: '';
        @extend %overlay-full;
        @extend %bg-cover;
        background-size: 100% 100%;
        z-index: -1;
        background-image: url(../img/decor/inspirate-main-bg.svg);
    }
    &__decor{
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        .decor{
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            &-1{
                @include aspect-ratio(620,577,true);
                position: absolute;
                width: calc(620/1366)*100%;
                top: 10%;
                right: 0;
                transform: translateX(50%);
                background-image: url(../img/decor/splatter-yellow2.svg);
                // max-width: 200px;
            }
            &-2{
                @include aspect-ratio(340,270,true);
                position: absolute;
                width: calc(340/1366)*100%;
                bottom: 5%;
                left: 0;
                transform: translateX(-30%);
                background-image: url(../img/decor/splatter-pink.svg);
                // max-width: 250px;
            }
            // &-3{
            //     position: absolute;
            //     width: 105/1366*100%;
            //     right: 140/1366*100%;
            //     top: 5%;
            // }
            // &-4{
            //     position: absolute;
            //     width: 100/1366*100%;
            //     left: 80/1366*100%;
            //     top: 5%;
            // }


        }
    }
    &__container{
        position: relative;
        padding-top: 10vmax;
        text-align: center;

    }
    &__single{
        margin: 2vmax auto 5vmax;
        max-width: var(--descriptionWidth);
        @include r(767){
            @include css-lock(10,18,350,767);
        }
        .inspiration_home &{
            margin-bottom: 0;
        }
        .inspiration-list__title{
            justify-content: center;
        }
    }

    &-list{
        margin-top: 2em;
        margin-bottom: 4em;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 45px;
        &__box{
            img{
                width: 100%;
            }
        }
        &__item{

        }
        &__title{
            margin-top: -40px;
            display: flex;
            align-items: center;
            font-family: $title-font;
            .top,
            .bottom{
                font-size: em(24,18);
            }
        }
        &__who{
            padding: .5em 2em .5em 1.5em;
            flex: 0 0 60%;
            text-align: left;
            position: relative;
            &::before{
                content: '';
                @extend %bg-full;
                @extend %overlay-full;
                background-image: url(../img/decor/inspiration-text-bg.svg);
            }
            & > *{
                position: relative;
            }
        }
        &__arrow{
            margin-left: 2em;
            .svg-icon{
                width: 4em;
                height: 2.7em;
            }
        }
    }
}



