.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    line-height: 1.2;
    background-color: transparent;
    font-size: 24px;
    font-family: $title-font;
    @include r($menuMob - 1){
        @include css-lock(12,22,350,1023);

    }
    a{
        color: #ffffff;
        text-decoration: none;
    }
    .btn{
        color: var(--color-secondary);
    }
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 em(30,24);
        transform: translateY(em(30,24));
        transition: transform .4s ease-out;
        @include r(991){
            transform: translateY(em(15,24));
            padding: 0 em(15,24);
        }
    }
    &__left{
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
        @include r($menuMob -1){
            width: 100%;
            flex: 1 1 100%;
        }
    }
    &__logo{
        width: em(200,24);
        // will-change: width;
        transition: .35s ease;
        transform-origin: 0 50%;
        @include r(767){

        }
    }
    &__switch{
        display: flex;
        transition: .35s ease;
        transform-origin: 0 50%;
        @media (max-width: 450px) {
            position: absolute;
            top: 100px;
            display: none;
        }

    }
    .lang-switch{
        margin-left: em(10,24);
        @include r($menuMob -1){
            margin-left: auto;
        }
        &__item{
            position: relative;
            line-height: calc(32/24)*1em;
            padding: em(7,24) em(15,24);
            &::before{
                content: '';
                position: absolute;
                width: em(40,24);
                height: em(36,24);
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                transition: opacity $transition-base;
                color: $color-primary;
                border: 1px solid;
                background-color: currentColor;
                border-radius: 4px;
                // background-image: url(../img/decor/lang-switcher-bg.svg);
                // background-size: cover;
                // background-repeat: no-repeat;
                opacity: 0;
            }
            &.is-active{
                &::before,
                .lang-switch__text{
                    opacity: 1;
                }
            }
            &:not(.is-active):hover{
                .lang-switch__text{
                    opacity: .9;
                }
            }
        }
        &__text{
            position: relative;
            opacity: .6;
            transition: $transition-base;
        }
    }

    &__center{
        display: flex;
        align-items: center;
        @include r($menuMob - 1){
            position: fixed;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            justify-content: center;
            font-size: 10vmin;
            display: none;
            text-align: center;
        }
    }


    &__nav{
        .nav{
            &__list{
                @extend %reset-list;
                display: flex;
                position: relative;
                @include r($menuMob - 1){
                    flex-direction: column;
                    align-items: center;
                }
            }
            &__item{
                @include rmin($menuMob){
                    & + .nav__item{
                        margin-left: em(28,24);
                    }
                }

                @extend %showFromBottom;
                @include r($menuMob - 1){
                    margin-bottom: .4em;
                }

            }
            &__link{
                padding: em(6,24) 0;
                position: relative;
                line-height: calc(32/24)*1em;
                overflow: hidden;

                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    background-color: currentColor;
                    opacity: 0;
                    transform-origin: 50% 100%;
                }
                &.is-active{
                    &::after{
                        opacity: 1;
                    }
                }
                &:not(.is-active):hover::after{
                    animation: underline-decor .4s both;
                }
            }
        }
    }
    .header__right{
        @include r($menuMob - 1){
            margin-left: auto;
            .header__label{
                display: none;
            }
        }
        @include rmin($menuMob){
            display: flex;
            align-items: center;
            position: relative;
            .header__label{
                margin: 0;
                position: absolute;
                width: 100%;
                text-align: center;
                bottom: 100%;
                line-height: 1.5;
                font-size: .8em;
                text-transform: capitalize;
            }
        }
        @include r(767){
            .btn_pencil{
                // .text{
                //     @include css-lock(6,20,320,767);
                //     @include r(450){
                //         opacity: 0;
                //     }

                // }
            }
        }
    }

    .lang-switch{
        &__item{
            display: inline-block;
            position: relative;
            &.is-active {
                opacity: 1;
            }
        }
    }
}

body.is-scrolled{
    .header{
        &__container{
            transform: translateY(0);
        }
        &__logo{
            // width: 5em;
            transform: scale(.7);
        }
        &__switch{
            @include rmin(767){
                transform: translateX(-2em);
            }
        }
    }
    &:not(.menu-open){
        .layout__decor.layout__decor_top{
            transform: scaleY(.7);
        }
    }
}



%showFromBottom{
    @media (max-width: 1199px ) {
        opacity: 0;
        .menu-open &{
            animation: slide-in-bottom 0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
        }
        @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
                animation-delay: .2s + ($i * .1s);
            }
        }
    }

}


@include r($menuMob - 1){

    .menu-open{
        .header__center{
            display: flex;
        }
        .layout__decor.layout__decor_top{
            transform: scaleY(16.5);
            // height: 500vh;
            .wave{
                animation-play-state: paused;
                // min-width: 1000vh;
            }
        }
        .lang-switch{
            @media (max-width: 450px) {
                display: flex;
            }
        }
    }

}





@keyframes underline-decor {
    0%{
        opacity: 1;
        transform: scaleX(.6) scaleY(.2);
    }
    50%{
        opacity: 1;
        transform: scaleX(.6) scaleY(2);
    }
    100%{
        opacity: 1;
        transform: scaleX(1) scaleY(1);
    }
}

@keyframes slide-in-bottom {
    0% {
      transform: translateY(.5em);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
}




// .header {
//     background-color: #493280;

// }


// .canvas-wrap {
//     z-index: 100;
//     max-width: 100%;
//     overflow: hidden;
//     position: absolute;
//     transform: translateY(-50%);
//   }

//   canvas {
//     display: block;
//   }
