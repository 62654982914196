// .win-page{
//     background-image: url(../img/decor/win-bg.svg);
//     background-size: 100% 100%;
//     background-position: center;
//     background-repeat: no-repeat;
// }


.win{
    padding: mIn(100px, 5vh) 0;
    position: relative;
    text-align: center;
    z-index: 1;
    width: 100%;
    max-width: initial;
    // min-height: mIn(80vh, 542px);
    // display: flex;
    // align-items: center;
    @include rmin(1921){
        @include css-lock(20,35,1921,2600);
    }
    &__decor{
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        .decor{
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            &-1{
                @include aspect-ratio(620,577,true);
                position: absolute;
                width: calc(620/1366)*100%;
                bottom: 100%;
                right: 0;
                transform: translate(50%,50%);
                background-image: url(../img/decor/splatter-yellow2.svg);
            }
            &-2{
                @include aspect-ratio(240,220,true);
                position: absolute;
                width: calc(240/1366)*100%;
                top: calc(320/900)*100vh;
                left: 0;
                bottom: 0;
                background-image: url(../img/decor/splatter-blue.svg);
            }
        }
    }
    &__title{
        position: relative;
        display: block;
        width: min-content;
        margin: 0 auto .5em;
        // color: var(--color-secondary);
        // &::before{
        //     content: '';
        //     @extend %overlay-full;
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%,-50%) scaleX(3.5) scaleY(2.6);
        //     z-index: -1;
        //     @extend %bg-full;
        //     background-image: url(../img/decor/win-title-bg.svg);
        // }
    }
    &__container{
        &.container{
            max-width: var(--descriptionWidth);
        }
    }
    &__container-prize{
        &.container{
            max-width: 1100px;
        }
    }
    a{
        color: #ffffff;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include underlineHideOnHover;
    }
    &__container-prize{
        margin-top: 3em;
        padding-bottom: 3vmax;
    }
    .win-prizes{
        display: flex;
        justify-content: space-around;
        @include r(767){
            flex-wrap: wrap;
            justify-content: center;
        }
        &__title{
            margin-top: .4em;
            text-transform: uppercase;
            font-family: $title-font;
            position: relative;
        }
        // &__item:nth-child(1){
        //     .win-prizes__title{
        //         transform-origin: 0 100%;
        //         transform: rotate(-4deg);
        //     }
        // }
        &__item:last-child{
            .win-prizes__title{
                transform-origin: 100% 100%;
                transform: rotate(4deg);
            }
        }
    }
}

