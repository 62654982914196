.menu-trigger {
    position: relative;
    margin-right: 10px;
    margin-left: 20px;
    z-index: 10;
    width: 14px;
    height: 16px;
    color: currentColor;
    display: flex;
    align-items: center;
    pointer-events: all;
    &::before{
        content: '';
        position: absolute;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        top: -10px;
        left: -10px;
        background: transparent;
    }

    @include rmin($menuMob) {
        display: none;
    }

    span {
        margin-left: auto;
        display: block;
        width: 100%;
        height: 2px;
        background-color: currentColor;
        position: relative;
        transition: transform $transition-base;
        border-radius: 5px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 21px;
            height: 100%;
            left: 0;
            background-color: currentColor;
            transition: inherit;
            border-radius: inherit;
        }

        &::before {
            top: -7px;
            transform-origin: 100% 35%;
        }

        &::after {
            bottom: -7px;
            transform-origin: 100% 65%;
        }

        .menu-open & {
            background-color: transparent;

            &::before {
                transform: rotate(-45deg);
                background-color: $color-text;
            }

            &::after {
                transform: rotate(45deg);
                background-color: $color-text;
            }
        }
    }
}