.intro{
    margin-top: -10vh;
    position: relative;
    z-index: 0;
    &__bg{
       @extend %overlay-full;
       z-index: 0;
       object-fit: cover;
       object-position: bottom;
       &[width]{
           height: 100%;
       }
    }
    .intro__logo{
        width: auto;
        @media  (orientation: landscape){
            max-width: 110vh;
        }

    }
    &__container{
        position: relative;
        display: flex;
        min-height: 100vh;
        // height: mAx(800px, calc(100vh - var(--headerHeight)));
        align-items: center;
        justify-content: center;
    }
    &__end{
        text-align: center;
        max-width: 40em;
        p{
            max-width: 30em;
            margin: 0 auto;
        }
    }
}

.description{
    text-align: center;
    position: relative;
    &__container.container{
        max-width: var(--descriptionWidth);
        position: relative;
    }
    &__decor{
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        .decor{
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            &-1{
                @include aspect-ratio(196,154,true);
                position: absolute;
                width: calc(196/1366)*100%;
                top: 100px;
                left: calc(50/1366)*100%;
                background-image: url(../img/decor/splatter-yellow.svg);
                // max-width: 200px;
            }
            &-2{
                @include aspect-ratio(240,220,true);
                position: absolute;
                width: calc(240/1366)*100%;
                top: calc(320/900)*100vh;
                right: 0;
                background-image: url(../img/decor/splatter-blue.svg);
                // max-width: 250px;
            }
        }
    }
    &__pencil{
        @include r(767){
            max-width: 75%;
        }
    }
}

.arrow-down{
    position: absolute;
    display: block;
    width: min-content;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    bottom: 7%;
    font-family: $title-font;
    color: #ffffff;
    text-decoration: none;
    .svg-icon{
        display: block;
        width: 1em;
        height: 1em;
        margin: 0 auto;
    }
}


.home-page_close{
    .header .lang-switch{
        display: block;
        position: relative;
        top: 0;
    }
    .layout__main{
        display: flex;
        flex-flow: column wrap;
    }
    .intro{
        flex: 1;
        display: flex;
        align-items: center;
        &__container{
            min-height: 70vh;
        }
    }
}
