.artists{
    padding-top: 3vmax;
    position: relative;
    z-index: 1;
    &__decor{
        position: absolute;
        z-index: 0;
        width: 100%;
        .decor{
            position: absolute;
            width: calc(105/1366)*100%;
            top: 10em;
            right: calc(50/1366)*100%;
        }
    }
    &__title{
        text-align: center;
    }
    &__description{
        max-width: var(--descriptionWidth);
        margin: 0 auto;
        text-align: center;
    }
    &__list{
        @extend %reset-list;

    }

    &-list{
        padding-bottom: 5vmax;
        &__item{
            margin-top: 5vmax;
            position: relative;
            @include rmin(1024){
                &:nth-child(even){
                    .artists-list__container{
                        flex-direction: row-reverse;
                    }
                    .artist-visual__arrow{
                        right: unset;
                        left: 0;
                        transform: scaleX(-1);
                    }
                }
            }
        }
        &__container{
            display: flex;
            align-items: center;
            @include rmin(1024){
                justify-content: space-between;
            }
            @include r(1023){
                flex-wrap: wrap;
                justify-content: center;
            }
        }
        &__visual{
            position: relative;
            @include rmin(1024){
                flex: 1 1 calc(50% - 20px);
                max-width: calc(50% - 20px);
            }
        }
        &__decor{
            position: absolute;
            width: calc(100/1366)*100vw;
            &.top-left{
                top: 0;
                left: 0;
                transform: translate(-100%,-100%);
            }
            &.top-right{
                top: 0;
                right: 0;
                transform: translate(100%,-100%);
            }
        }

        &__info{
            @include rmin(1024){
                flex: 1 1 calc(50% - 20px);
                max-width: calc(50% - 20px);
            }
        }
        &__description{
            max-width: 500px;
        }
        &__fragment{
            margin-top: 1.5em;
        }
        &__instagram-link{
            &.btn{
                padding: 0;
                min-height: 1.4em;
                margin-top: 1em;
                .decor{
                    transform: scaleY(-1) translateY(0.25em);
                }
            }
        }
        &__btn{
            font-size: 24px;
            @include r(767){
                @include css-lock(18,24,350,767);
                max-width: calc(100vw - 2em);
                .text{
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 90%;
                    text-overflow: ellipsis;
                }
            }
            .btn{
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
.artist-info{
    &__inner{
        margin: 0 auto;
        text-align: left;
        max-width: 500px;
    }
}
.artist-visual{
    &__picture{
        position: relative;
        z-index: 1;
        text-align: center;
    }
    &__decor{
        @extend %overlay-full;

    }
    &__bg{
        @include abs-center();
        @include aspect-ratio(620,580,true);
        svg{
            @extend %overlay-full;
            fill: currentColor;
        }
    }
    &__arrow{
        width: 90px;
        height: 90px;
        position: absolute;
        top: 0;
        right: 0;
        svg{
            width: 100%;
            height: 100%;
            fill: currentColor;
        }
    }
}
