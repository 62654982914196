.privacy-page {
    line-height: 1.428571429;
    scroll-behavior: smooth;

    p {
        margin: 0 0 10px;
    }

    .row {
        margin-right: 0px;
        margin-left: 0px;

        &::before,
        &::after {
            display: table;
            content: " ";
        }

        &::after {
            clear: both;
        }
    }

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11 {
        float: left;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-6 {
        width: 100%;

        @media (min-width: 992px) {
            width: 50%;
        }
    }

    .col-md-4 {
        width: 100%;

        @media (min-width: 992px) {
            width: 33.33333333333333%;
        }
    }

    .col-md-12,
    .col-md-6 {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-left: 15px;
    }

    h1 {
        font-size: 44px;
        margin-bottom: 30px;
    }
    h2,
    h3 {
        margin-top: 1em;
        margin-bottom: .5em;
        line-height: 1.1;
    }

    ul,
    ol {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .d-flex {
        display: flex;
        flex-wrap: wrap;
    }


    /* Set padding to keep content from hitting the edges */
    .body-content {
        padding-left: 0;
        padding-right: 0;
        @include rmin(992) {
            max-width: 804px;
        }
        a {
            text-decoration: none;
            &:hover,
            &:focus {
                opacity: .7;
            }
        }

        &.--nl {
            .btn {
                display: inline-block;
                padding: 6px 12px;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.428571429;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                cursor: pointer;
                border: 1px solid transparent;
                border-radius: 4px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                -o-user-select: none;
                user-select: none;
                text-transform: unset;
            }

            .btn-default {
                color: #333333;
                background-color: #ffffff;
                border-color: #cccccc;
            }



            .policy-body {
                scroll-behavior: smooth;
            }

            #dynamicPrivacyPolicy>div {
                padding-left: 15px;
                padding-right: 15px;
            }

            .col-tab-section {
                padding: 10px;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
                @include r(991){
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
            .policy-header {
                margin-bottom: 15px;
            }
            .policy-image {
                text-align: center;
                padding: 4%;
                height: 168px;
            }
            .policy-top-left {
                background-color: #BDC627;
                min-height: 460px;
                height: 100%;
                padding: 2%;
                color: white;
            }
            .policy-heading {
                font-size: 2.5em !important;
                font-weight: 900;
            }
            @media (max-width: 960px) {
                .policy-heading {
                    font-size: 1.4em !important;
                }
            }
            .policy-top-info {
                min-height: 140px;
                height: auto !important;
            }
            .policy-h2 {
                font-size: 24px;
                margin-top: 1em;
                margin-bottom: 0.5em;
                font-weight: 900;
            }
            .policy-top-right {
                background-color: #2bbecb;
                float: right;
                min-height: 460px;
                height: 100%;
                padding: 2%;
                color: white;
            }
            .policy-bottom-left {
                background-color: #F96505;
                min-height: 460px;
                height: 100%;
                padding: 2%;
                color: white;
            }
            .policy-bottom-right {
                background-color: #D22639;
                float: right;
                min-height: 460px;
                height: 100%;
                padding: 2%;
                color: white;
            }
            .btn-policy-top-middle {
                background-color: #2bbecb;
                color: white;
                border-color: #0AA2AC;
                border-width: 2px;
                border-radius: 4px;
                max-width: 100%;
                white-space: normal;
                &:hover{
                    color: #333333;
                    background-color: #ebebeb;
                    border-color: #adadad;
                }
            }

            .navlink {
                height: 60px;
                width: 100%;
                background-color: #4F2270;
                display: flex;
                align-items: center;
                color: white;
                margin: 1px;
                background-image: url(../../img/privacy/icon-arrow-down.png);
                background-repeat: no-repeat;
                background-position: 7%;
                font-size: 11px;
                padding-left: 20%;
                padding-right: 2%;
                outline: 2px solid #fff;
            }

            nav {
                width: 100%;
            }

            nav select {
                display: none;
                background-color: #4F2270;
                max-width: 100%;
                min-height: 50px;
                height: auto !important;
                min-width: 100%;
                color: white;
            }

            @media (max-width: 960px) {
                nav .horizontal-list {
                    display: none;
                }

                nav select {
                    display: inline-block;
                }
            }

            .PolicyDescription h2 {
                font-size: 36px;
                margin-bottom: 20px;
                font-weight: 900;
            }

            .PolicyDescription h3 {
                font-size: 24px;
                margin-bottom: 16px;
                font-weight: 900;
                text-transform: uppercase;
            }
        }

        &.--fr {
            .btn {
                display: inline-block;
                padding: 6px 12px;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.428571429;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                cursor: pointer;
                border: 1px solid transparent;
                border-radius: 4px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                -o-user-select: none;
                user-select: none;

                &:hover,
                &:focus {
                    color: black;
                    text-decoration: none;
                    background-color: #ebebeb;
                    border-color: #adadad;
                }
            }



            .policy-body {

                scroll-behavior: smooth;
            }

            #dynamicPrivacyPolicy>div {
                padding-left: 15px;
                padding-right: 15px;
            }


            .col-tab-section {
                padding: 10px;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
                @include r(991) {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }

            .policy-header {
                margin-bottom: 15px;
            }

            .policy-image {
                text-align: center;
                padding: 4%;
                height: 168px;
            }

            .policy-top-left {
                background-color: #BDC627;
                min-height: 550px;
                height: 100%;
                padding: 2%;
                color: white;
                @include r(991){
                    min-height: 400px;
                }
            }

            .policy-top-info {
                min-height: 80px;
                height: auto !important;
            }

            .policy-h2 {
                font-weight: 700;
            }

            .btn-policy-top-left {
                background-color: #BDC627;
                color: white;
                border-color: #8B9127;
                border-width: 2px;
                border-radius: 4px;
            }

            .policy-top-right {
                background-color: #2bbecb;
                float: right;
                min-height: 550px;
                height: 100%;
                padding: 2%;
                color: white;
                @include r(991){
                    min-height: 400px;
                }
            }

            .btn-policy-top-middle {
                background-color: #2bbecb;
                color: white !important;
                border-color: #0AA2AC;
                border-width: 2px;
                border-radius: 4px;
                max-width: 100%;
                white-space: normal;
            }

            .btn-policy-top-middle:hover {
                color: black !important;
            }

            .btn-policy-top-right {
                background-color: #2bbecb;
                color: white;
                border-color: #0AA2AC;
                border-width: 2px;
                border-radius: 4px;
                margin-top: 30px;
            }

            .policy-top-right h2 {
                font-weight: 700;
            }

            .policy-bottom-left {
                background-color: #F96505;
                min-height: 550px;
                height: 100%;
                padding: 2%;
                color: white;
                @include r(991) {
                    min-height: 400px;
                }
            }

            .btn-policy-bottom-left {
                background-color: #F96505;
                color: white;
                border-color: #9D3502;
                border-width: 2px;
                border-radius: 4px;
                margin-top: 30px;
            }

            .policy-bottom-right {
                background-color: #D22639;
                float: right;
                min-height: 550px;
                height: 100%;
                padding: 2%;
                color: white;
                @include r(991) {
                    min-height: 400px;
                }
            }

            .btn-policy-bottom-right {
                background-color: #D22639;
                color: white;
                border-color: #8E1825;
                border-width: 2px;
                border-radius: 4px;
                margin-top: 40px;
            }

            .navlink {
                height: 50px;
                width: 100%;
                background-color: #4F2270;
                display: inline-block;
                color: white;
                padding-top: 6%;
                margin: 1px;
                background-image: url(../../img/privacy/icon-arrow-down.png);
                background-repeat: no-repeat;
                background-position: 7%;
                font-size: 11px;
                padding-left: 20%;
                padding-right: 2%;
                outline: 2px solid #fff;
            }

            nav {
                width: 100%;
            }

            nav select {
                display: none;
                background-color: #4F2270;
                max-width: 100%;
                min-height: 50px;
                height: auto !important;
                min-width: 100%;
                color: white;
            }

            @media (max-width: 960px) {
                nav .horizontal-list {
                    display: none;
                }

                nav select {
                    display: inline-block;
                }
            }

            .PolicyDescription h2 {
                font-size: 36px;
                margin-bottom: 6px;
                font-weight: 700;
            }

            .PolicyDescription h3 {
                font-size: 24px;
                margin-bottom: 6px;
                font-weight: 700;
            }

            #scroll-top {
                display: none;
                position: fixed;
                bottom: 20px;
                right: 5px;
                z-index: 99;
                font-size: 10px;
                border: none;
                outline: none;
                background-color: #2bbecb;
                color: white;
                cursor: pointer;
                padding: 10px;
                border-radius: 4px;
            }

            #scroll-top:hover {
                background-color: #555;
            }

            .hyperlink {
                font-weight: bold;
                text-decoration: underline !important;
            }

            // FR
            &.french {
                .policy-body {
                    border: none;
                }

                @media (max-width: 960px) {
                    .policy-header {
                        background-color: #F1F1F1;
                    }

                    .mondelez-logo {
                        height: 45px;
                    }

                    .brand-logo {
                        height: 40px;
                    }

                    .policy-body {
                        border: none;
                    }
                }

                .col-tab-section {
                    padding: 10px;
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                    @include r(991) {
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                    }
                }

                .policy-header {
                    margin-bottom: 15px;
                }

                .policy-image {
                    text-align: center;
                    padding: 4%;
                    max-width: 100%;
                    height: 168px;
                }

                .policy-top-left {
                    background-color: #287819;
                    min-height: 460px !important;
                    height: 100%;
                    padding: 2% !important;
                    color: white;

                    @include r(575) {
                        min-height: 520px !important;
                    }
                }

                .policy-heading {
                    font-size: 2.5em !important;
                    font-family: "MDLZ BITE TYPE", 'Arial Black', Gadget, sans-serif;
                    margin-bottom: 10px;
                }

                @media (max-width: 960px) {
                    .policy-heading {
                        font-size: 1.4em !important;
                    }
                }

                .policy-top-info {
                    min-height: 140px;
                    height: auto !important;
                    color: white;
                }

                .policy-h2 {
                    font-size: 24px;
                    margin-top: 1em;
                    margin-bottom: .5em;
                    font-family: "MDLZ BITE TYPE", 'Arial Black', Gadget, sans-serif;
                }

                .btn-policy-top-left {
                    color: white !important;
                    float: right;
                    margin-top: 0;
                }

                .policy-top-right {
                    background-color: #2D6EAA;
                    float: right;
                    min-height: 460px !important;
                    height: 100%;
                    padding: 2% !important;
                    color: white;
                }

                .btn-policy-top-right {
                    color: white !important;
                    float: right;
                    margin-top: 0;
                }

                .policy-bottom-left {
                    background-color: #E6AF23;
                    min-height: 474px !important;
                    height: 100%;
                    padding: 2% !important;
                    color: white;

                    @include r(575) {
                        min-height: 520px !important;
                    }
                }

                .btn-policy-bottom-left {
                    color: white !important;
                    float: right;
                }

                .policy-bottom-right {
                    background-color: #A52323;
                    float: right;
                    min-height: 474px !important;
                    height: 100%;
                    padding: 2% !important;
                    color: white;
                }

                .policy-bottom-right p {
                    color: white;
                }

                .btn-policy-bottom-right {
                    color: white !important;
                    float: right;
                    margin-top: 0;
                }

                .policy-header-top,
                .policy-header-top a {
                    color: #4f2170 !important;
                    text-align: center;
                }

                .policy-header-top-desc {
                    color: #5a2b7c;
                    font-size: 15px;
                    text-align: center;
                }

                .PolicyDescription h2 {
                    color: #E18719;
                    font-size: 36px;
                    margin-bottom: 20px;
                    font-weight: 900;
                    margin-top: 30px;
                    font-family: "MDLZ BITE TYPE", 'Arial Black', Gadget, sans-serif;
                }

                .PolicyDescription h3 {
                    color: #E18719;
                    font-size: 24px;
                    font-family: "MDLZ BITE TYPE", 'Arial Black', Gadget, sans-serif;
                    line-height: 1;
                    text-transform: uppercase;
                }

                #scroll-top {
                    background-color: #2D6EAA;
                }

                #scroll-top:hover {
                    background-color: #555;
                }

                .hyperlink {
                    font-weight: bold;
                    text-decoration: underline !important;
                }

                .ul-dynamic-policy {
                    list-style: disc !important;
                    -webkit-padding-start: 40px;
                    line-height: 1.54;
                }

                .ul-dynamic-policy li {
                    margin: 0 0 0 28px;
                }

                .ul-dynamic-policy-Sub {
                    list-style: circle !important;
                    -webkit-padding-start: 60px;
                }

                .btn:before,
                .filter-button:before {
                    margin-top: 0px;
                }

                .btn:hover {}

                .btn-default {
                    background-color: transparent;
                    border: none;
                }

                .btn-default:hover,
                .btn-default:focus,
                .btn-default:active,
                .btn-default.active,
                .open .dropdown-toggle.btn-default {
                    color: #E18719 !important;
                    background-color: transparent;
                    border: none;
                    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
                }

                .footerContent {
                    height: 55px;
                }
            }
        }
    }

    #scroll-top {
        display: none;
        position: fixed;
        bottom: 20px;
        right: 5px;
        z-index: 99;
        font-size: 10px;
        border: none;
        outline: none;
        background-color: #2D6EAA;
        color: white;
        cursor: pointer;
        padding: 10px;
        border-radius: 4px;
    }

    #scroll-top:hover {
        background-color: #555;
    }

    .hyperlink {
        font-weight: bold;
        text-decoration: underline !important;
    }

    .policy-header-top-desc {
        text-align: center;
    }
}