.footer{
    font-size: 14px;
    font-family: $font-alwyn;
    padding-bottom: rem(20);
    text-align: center;
    flex: 0 0 auto;
    width: 100%;
    margin-top: auto;
    position: relative;
    z-index: 2;
    color: $color-text;
    &__container{
        padding-left: rem(25);
        padding-right: rem(25);
    }
    @include rmin(2000){
        z-index: 1;
    }
    @include r(575){
        padding-bottom: rem(15);
        &__container{
            padding-left: rem(15);
            padding-right: rem(15);
        }
    }

    &__row{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
    }
    &__column{
        display: flex;
        width: 50%;
        margin-bottom: .5rem;
        &_social{
            align-items: center;
            @include r(575){
                position: absolute;
            }
            @include rIn(331,575){
                bottom: 20px;
            }
            @include r(330){
                bottom: 35px;
            }
        }
        @include r(575){
            width: auto;
        }
    }
    &__logo {
        @include r(1920){
            width: 104px;
        }
        img {
            width: 100%;
            display: block;
        }
    }
    &__socials {
        margin-left: auto;
    }
    &__links {
        flex: 0 0 auto;
        max-width: 100%;
        margin-bottom: rem(-6);
        @include r(1024){
            width: 100%;
            text-align: center;
        }
        @include r(575){
            margin-bottom: rem(40);
        }
        &-item {
            position: relative;
            display: inline-block;
            margin-bottom: rem(6);
            color: inherit;
            text-decoration: none;
            line-height: calc(32/24)*1em;
            // overflow: hidden;
            @include rmin(2200){
                font-size: rem(18);
            }
            @include r(575){
                margin-bottom: rem(10);
            }

            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0;
                left: 0;
                background-color: currentColor;
                opacity: 0;
                transform-origin: 50% 100%;
            }
            &.is-active{
                &::after{
                    opacity: 1;
                }
            }
            &:not(.is-active):hover::after{
                animation: underline-decor .4s both;
            }

        }
        &-dot {
            display: inline-block;
            vertical-align: middle;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            margin: 0 16px;
            background-color: $color-text;
            opacity: .2;
        }
    }
    &__copyright {
        flex-grow: 1;
        max-width: 100%;
        text-align: right;
        @include rmin(2200) {
            font-size: rem(18);
        }
        @include r(1024) {
            width: 100%;
            text-align: center;
            margin-top: em(15);
        }
        @include r(575){
            font-size: rem(11);
        }
    }
    .socials{
        display: flex;
        align-items: center;
        justify-content: center;
        &__item {
            display: block;
            margin-right: 24px;
            transition: transform 0.2s ease-in-out;
            fill: inherit;
            &:hover{
                transform: scale(1.2);
            }
            &:last-child {
                margin-right: 0;
            }
            svg{
                display: block;
                fill: #EE3124;
                @include r(575){
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
    .accent {
        position: relative;
        &:before {
            content: "-";
            display: block;
            position: absolute;
            bottom: 47%;
            left: 50%;
            transform: translateX(-50%);
            line-height: 1;
            font-size: 90%;
        }
    }
}


.footer-wave{
    .layout__footer{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 200%;
            left: 0;
            bottom: 0;
            @extend %bg-full;
            background-image: url(../img/decor/wave-bottom.svg);
        }
    }
}
