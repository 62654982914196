@font-face {
    font-family: 'Intro';
    src: url('../fonts/Intro/Intro-Bold.woff2') format('woff2'),
        url('../fonts/Intro/Intro-Bold.woff') format('woff'),
        url('../fonts/Intro/Intro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro';
    src: url('../fonts/Intro/Intro-Book.woff2') format('woff2'),
        url('../fonts/Intro/Intro-Book.woff') format('woff'),
        url('../fonts/Intro/Intro-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro';
    src: url('../fonts/Intro/Intro-Black.woff2') format('woff2'),
        url('../fonts/Intro/Intro-Black.woff') format('woff'),
        url('../fonts/Intro/Intro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alwyn';
    src: url('../fonts/Alwyn/Alwyn.woff2') format('woff2'),
        url('../fonts/Alwyn/Alwyn.woff') format('woff'),
        url('../fonts/Alwyn/Alwyn.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Cardenio Modern';
    src: url('../fonts/CardenioModern/CardenioModern-Bold.woff2') format('woff2'),
        url('../fonts/CardenioModern/CardenioModern-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}