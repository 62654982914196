.show-modal{
    .layout__decor.layout__decor_top{
        min-height: 500vh;
        .wave{
            animation-play-state: paused;
        }
    }
    .modal.is-open{
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
    }
    &-coloring, &-confirm {
        .layout__decor.layout__decor_top {
            min-height: calc(var(--headerHeight)*2.5);
        }
    }
    &-coloring {
        .popup-coloring {
            opacity: 1;
            transform: translateY(0);
            pointer-events: auto;
        }
    }
    &-confirm {
        .popup-confirm {
            opacity: 1;
            transform: translateY(0);
            pointer-events: auto;
        }
    }
    &-info {
        .layout__decor.layout__decor_top {
            min-height: calc(var(--headerHeight)*2.5);
        }
    }
}

.modal{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    transition: transform .35s ease-in, opacity .2s ease-in;
    opacity: 0;
    transform: translateY(1em);
    pointer-events: none;
    &__overlay{
        z-index: 1;
        @extend %overlay-full;
    }
    &__inner{
        margin: auto;
        position: absolute;
        max-width: 555px;
        background: #D4CEE8;
        padding: 2em;
        border-radius: 6px;
        text-align: center;
        box-shadow: 0px 40px 80px rgba(0, 0, 0, .2);
        color: var(--color-secondary);
        z-index: 2;
        @include r(767){
            padding-left: 1em;
            padding-right: 1em;
        }
    }
    &__text{
        margin-bottom: 1.5em;
    }
    &__btns{
        display: flex;
        flex-flow: row wrap;
        // align-items: center;
        justify-content: space-between;
        .btn{
            flex: 1;
            min-width: fit-content;
            &.modal-btn_company{
                padding: 1em;
                margin-bottom: .5em;
                max-width: 32%;
                display: flex;
                flex-flow: column wrap;
                border-radius: .5em;
                background-color: var(--color-text);
                .text{
                    margin-top: auto;
                    position: relative;
                    text-decoration: none;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        bottom: 0;
                        left: 0;
                        background-color: currentColor;
                        transform: translateY(3px);
                        transition: $transition-base;
                        backface-visibility: hidden;
                        opacity: 0;
                        pointer-events: none;
                    }
                }
                .logo-wrap{
                    display: flex;
                    align-items: center;
                    height: 45px;
                }
                .logo{
                    margin-top: auto;
                    margin-bottom: auto;
                }
                &:hover{
                    .text::after{
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }
            &.modal-btn_next{
                max-width: 100%;
                flex: 1 1 100%;
                color: var(--color-primary);
                .text{
                    color: inherit
                }
            }
            &.modal-btn_close{
                width: 150px;
                margin: 0 auto;
                flex: initial;
                .svg-icon{
                    stroke: currentColor;
                }
                // max-width: 100%;
                // flex: 1 1 100%;
                // color: var(--color-primary);
                // .text{
                //     color: inherit
                // }
            }
        }
    }
    &__close{
        @extend %reset-btn;
        padding: .5em;
        position: absolute;
        top: 0;
        right: 0;
        .svg-icon{
            width: 1.5em;
            height: 1.5em;
            stroke: var(--color-purple);
        }
    }
    .btn{
        z-index: 2;
        &.js-toSelectedFrame{
            max-width: fit-content;
            margin: 0 auto;
        }
    }

    &__radio{
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;

        .radio-wrapper{margin-bottom: 1em;}
    }

    h3{
        margin-bottom: .5em;
    }

    &.popup-confirm{
        .modal__inner{
            top: 27%;
            min-height: 12em;
            max-width: 100%;
        }
    }
}
