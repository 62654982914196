%button{
    @extend %reset-btn;
    padding: .5em em(40,24) .55em em(40,24);
    display: inline-flex;
    flex: 0 0 auto;
    max-width: 100%;
    font-size: inherit;
    text-align: center;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: $transition-base;
    overflow: hidden;
    white-space: nowrap;
    line-height: calc(32/24)*1em;
    min-height: em(68,24);
    text-transform: capitalize;
    font-weight: 700;
    font-family: $title-font;
}



.btn{
    @extend %button;
    &_primary{
        color: #ffffff;
    }
    &_secondary{
        color: #ffffff;
    }
    &_decor{
        position: relative;
        transition: $transition-base;

        &::before,
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 55%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-image: url(../img/decor/button-bg.svg);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            z-index: -1;
            transition: $transition-base;
        }
        &::before{
            z-index: -2;
        }
        &::after{
            opacity: 0;
            filter: grayscale(0%);
            clip-path: polygon(5% 0%, 25% 50%, 0% 100%, 0% 100%, 0% 50%, 0% 0%);
            // clip-path: polygon(0% 0%, 25% 50%, 0% 100%, 0% 100%, 25% 50%, 0% 0%);
        }
        &:hover{
            &::after{
                animation: buttonClip .5s cubic-bezier(0.54, 0.35, 0.68, 0.63) both;
            }
        }
        &_green{

            &::before,
            &::after{
                background-image: url(../img/decor/button-bg_green.svg);
            }
        }
        &.in-progress{
            .text{
                display: none;
                & ~ .decor{
                    &:not(.decor_progress){
                        display: none;
                    }
                    &.decor_progress{
                        display: block;
                    }
                }
            }

        }
    }
    &_pencil{
        padding: 0 3.485em 0 2.125em;
        position: relative;
        min-height: 1.7em;
        overflow: visible;
        background-image: url(../img/decor/center2.svg);
        // background: linear-gradient(180deg, #493280 0%, #493280 4%, #8078B0 4%, #D3D1E8 46.88%, #D3D1E8 71.88%, #AAA5D2 96%, #493280 96%, #493280 100%);
        background-repeat: no-repeat;
        background-size: calc(100% - 5.4em) 102%;
        background-position: 2em 0;
        color: var(--color-secondary);
        .text{
            padding: 0 0 0 .4em;
        }
        &::before,
        &::after{
            content: '';
            position: absolute;
            background-size: auto 103%;
            height: 100%;
            top: 0;
            background-repeat: no-repeat;
        }
        &::before{
            left: 0;
            width: 2.125em;
            background-image: url(../img/decor/pencil_left.svg);
        }
        &::after{
            right: 0;
            width: 3.485em;
            background-image: url(../img/decor/pencil_right.svg);
        }

        [data-content]{
            transition: .4s ease-in;
            &::after{
                content: attr(data-content);
                position: absolute;
                left: 0.4em;
                top: 0;
                color: #ffffff;
                clip-path: polygon(0 0, 0 0, 0 0, 0 100%);
                transition: clip-path .35s ease-in;
            }

        }
        &:hover{
            [data-content]{
                &::after{
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                }
                & ~ .decor{
                    transition-delay: .37s;
                    color: #ffffff;
                }
            }
        }
        &.btn_lg{
            @include r(767){
                @include css-lock(21,40,350,767)
            }
        }
    }
    &_back{
        color: $color-primary;
        font-size: 24px;
        .svg-icon{
            margin-right: 0.5em;
            transform: scaleX(-1);
        }
    }
    &_underline{
        span{
            overflow: hidden;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: currentColor;
                left: 0;
                bottom: 0;
            }
        }
        &:hover{
            span{
                &::after{
                    animation: underline-hidden 0.6s cubic-bezier(0.445, 0.050, 0.550, 0.950) both;
                }
            }
        }
    }
    &_lg{
        font-size: rem(42);
        &.btn_pencil{
            .text{
                padding-right: 0;
                &~.decor{
                    margin-left: .24em;
                }
            }
        }
    }
    .text,.decor{
        position: relative;
    }

    .text ~ .decor{
        margin-left: .5em;
        transform: translateY(.25em);
    }
    .decor{
        .svg-icon_refresh{
            stroke: currentColor;
            fill: none;
        }
        &.decor_progress{
            transform: translateY(0);
            margin-left: 0;
            display: none;
            .svg-icon_refresh{
                animation: rotate 1s cubic-bezier(0.54, 0.35, 0.68, 0.63) infinite both;
            }
        }
    }
    .svg-icon{
        width: 1em;
        height: 1em;
        fill: currentColor;
    }
    &_icon-animate{
        &:hover{
            .svg-icon{
                animation: rotate 0.6s cubic-bezier(0.445, 0.050, 0.550, 0.950) both;
            }
        }
    }
    &:disabled{
        pointer-events: none;
        opacity: .5;
    }
}
.pencil__label{
    font-family: $title-font;
    font-size: 1.3em;
    line-height: 1.5;
    text-align: center;
}




@keyframes buttonClip {
    0%{
        opacity: 0;
        filter: grayscale(0%) saturate(0);
        clip-path: polygon(5% 0%, 25% 50%, 0% 100%, 0% 100%, 0% 50%, 0% 0%);
    }
    33%{
        opacity: 1;
        clip-path: polygon(33% 0%, 50% 50%, 33% 100%, 0% 100%, 5% 50%, 0% 0%);
    }
    66%{
        clip-path: polygon(75% 0%, 100% 50%, 66% 100%, 20% 100%, 25% 50%, 20% 0%);
    }
    100%{
        opacity: 1;
        filter: grayscale(17%) saturate(0.5);
        clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
        // opacity: 0;
    }
    100% {
        transform: rotate(180deg);
        // opacity: 1;
    }
}


@keyframes underline-hidden {
    0%{
        opacity: 1;
        transform: scaleX(1) scaleY(1);
    }
    50%{
        opacity: 1;
        transform: scaleX(.6) scaleY(2);
    }
    100%{
        opacity: 0;
        transform: scaleX(.6) scaleY(.2);
    }
}
