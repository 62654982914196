h1,.h1{
    font-weight: 900;
    font-size: rem(58);
    line-height: 1;
    margin: 0;
    font-family: $title-font;
    margin-bottom: .35em;
    text-transform: uppercase;
    @include r(575) {
        font-size: rem(38);
        @include r(330){
            font-size: rem(32);
        }
    }
}
h2,.h2{
    font-weight: 900;
    font-size: rem(50);
    line-height: 1;
    margin: 0;
    font-family: $title-font;
    margin-bottom: .5em;
    text-transform: uppercase;
    @include r(575){
        font-size: rem(32);
    }
}
h3,.h3{
    font-weight: 900;
    font-size: rem(38);
    line-height: 1;
    margin: 0;
    font-family: $title-font;
    margin-top: 1em;
    text-transform: uppercase;
    @include r(575) {
        font-size: rem(22);
    }
}
h4,.h4{
    font-weight: 900;
    font-size: rem(24);
    line-height: 1;
    margin: 0;
}

.highlight{
    color: $color-primary;
}